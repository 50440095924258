import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import { RootState } from "./redux/reducers";
import UnAuthRootRouter from "./routes/unauth-router";
import AuthRootRouter from "./routes/auth-router";

import { theme } from "./theme";
import React, { memo } from "react";

const App: React.FC = () => {
  const { UserDetails, isTCAccepted, selectedCountry, AccessToken } =
    useSelector((state: RootState) => state.User);

  const isUserAuthenticated =
    UserDetails?.role_type_details?.role_type_id === 2
      ? UserDetails.email_id.length &&
        isTCAccepted &&
        selectedCountry &&
        AccessToken
      : UserDetails?.email_id && AccessToken;
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
              isUserAuthenticated ? <AuthRootRouter /> : <UnAuthRootRouter />
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default memo(App);
