import { app_Actions } from "../../actions/appActions";
import { user_actions } from "../../actions/userActions";
import { IAppState, IAction } from "./interface-helpers";
import { REHYDRATE } from 'redux-persist';

const initialState: IAppState = {
  isTheme: "dark",
  isSideMenuOpen: false,
  activeSideMenuTab: "",
  showLoader: false,
  showThemeLoader: false,
  setLogout: false,
  setLoginData: {},
  toastMessage: {},
  setSort: {},
  isToken: true,
  setSmartAgroAppLayout: false,
};

const App = (state: IAppState = initialState, action: IAction) => {
  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        ...action.payload,
        showLoader: false
      };
    case app_Actions.Constants.isLoading:
      return {
        ...state,
        showLoader: action.payload,
      };
    case app_Actions.Constants.setSmartAgroAppLayout:
      return {
        ...state,
        setSmartAgroAppLayout: action.payload,
      };
    case user_actions.Constants.LogoutSuccess:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default App;
